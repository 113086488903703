<template>
  <div class="toggler">

    <div class="toggle" @click="onChange">
      <!-- <input :id="id" type="checkbox" :checked="val" :disabled="disabled" /> -->
      <div :id="id" :class="{'input': true, 'checked': props.modelValue, 'disabled': disabled}" type="checkbox" :disabled="disabled" ></div>
      <label :for="id">
        <i v-if="disabled" class="icon-lock"></i>  
      </label>
    </div>

    <div v-if="label" class="lbl" @click="onChange">{{ label }}</div>
  </div>
</template>

<script setup lang="ts">

interface PageProps {
  modelValue: boolean | number,
  disabled?: boolean,
  label?: string
}

const props = withDefaults(defineProps<PageProps>(), {
  modelValue: false,
  disabled: false,
  label: ''
})

const emits = defineEmits(['update:modelValue'])

const idCounter = ref(0)

const id = computed(() => 'toggle-switch-' + idCounter.value)

const onChange = () => {
  emits('update:modelValue', props.modelValue ? 0 : 1 )
}


// watch(() => props.modelValue, (new_val) => {
  // val.value = new_val
// })

 
</script>

<style scoped>
.toggler{
  display: flex;
  align-items: center;
  justify-content: flex-start; 
  gap: 5px;
}
.toggle {
  position: relative;
  width: 30px;  
  height: 17px; 
}
.lbl{
  cursor: pointer;
}
.toggle .input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle label {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #7d7d7d62;
  transition: .4s;
  border-radius: 17px;  
}

.toggle label:before {
  position: absolute;
  content: "";
  height: 13px; 
  width: 13px;  
  left: 2px;  
  bottom: 2px; 
  background-color: var(--un-background-color);
  transition: .4s;
  border-radius: 50%;
}

.toggle .input.checked + label {
  background-color: var(--gd-button-primary-back-color);
}

.toggle .input.focus + label {
  box-shadow: 0 0 1px var(--gd-button-primary-back-color);
}

.toggle .input.checked + label:before {
  transform: translateX(13px);  
}

.icon-lock {
  position: absolute;
  top: 50%;
  right: -1px;
  transform: translate(-50%, -50%);
  font-size: 0.6rem;
}
</style>
